@import '@aurora/shared-client/styles/mixins/_panel.pcss';
@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-form-wrapper,
.lia-reply {
  margin-top: 20px;
}

.lia-reply {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: 1px solid var(--lia-bs-border-color);
  font-weight: var(--lia-bs-font-weight-normal);
  @include panel-defaults();
  padding: 10px 25px;
  color: var(--lia-bs-gray-600);

  &:not(:disabled) {
    cursor: text;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  @include default-focus-outline();

  &.lia-is-read-only {
    cursor: not-allowed !important;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .lia-reply-cta {
    color: var(--lia-bs-gray-600);
  }
}
